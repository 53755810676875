import React from 'react'

import Root from '../components/Root'
import Meta from '../components/Meta'

const ErrorTemplate = () => (
    <Root>
        <Meta
            {...{
                title: 'Error 404: Not Found'
            }}
        />
        <div className="py-20 xl:py-28 bg-blue-light relative">
            <div className="container mx-auto relative px-10 pt-20">
                <div className="grid grid-cols-1 md:grid-cols-14">
                    <div className="hidden xl:block">
                        <div className="vert-label text-white">Error 404</div>
                    </div>
                    <div className="col-span-full xl:col-span-12 text-center">
                        <h1 className="line-styled-heading max-w-xs mx-auto mb-8 text-4xl md:text-5xl lg:text-6xl text-white font-extrabold uppercase"><strong>This page</strong> cannot be found</h1>
                        <p className="text-xl text-white">Check the page address or use the menu above to find what you are looking for.</p>
                    </div>
                </div>
            </div>
        </div>
    </Root>
)

export default ErrorTemplate